<template>
  <v-container fluid>
    <BreadHeader :title="model.view.name">
      <v-btn
        :color="HRDC_BLUE_THEME_COLOR"
        class="white--text"
        @click="toggleEventPage()"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </BreadHeader>

    <AError :api="apiDelEvent" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <!--BOC:[contents]-->
    <v-card>
      <v-data-table
        :headers="headers"
        :items="eventData"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-icon :color="getStatusColor(item.status)">
            {{ getStatusIcon(item.status) }}
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Delete {{ model.name.plural }}
        </v-toolbar>

        <div class="pa-4">Are you sure you want to delete this item ?</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="closeDelete"
          >
            Cancel
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="deleteItemConfirm()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="800px">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Edit {{ model.name.singular }}
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <!-- Title -->
              <v-col cols="12" sm="12" md="12" class="pb-0">
                <v-text-field
                  v-model="editedItem.title"
                  :error-messages="errorField.title"
                  dense
                  filled
                  label="Title"
                ></v-text-field>
              </v-col>

              <!-- Event Start Date -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="date1"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      v-model="editedItem.startDate"
                      :error-messages="errorField.startDate"
                      label="Event Start Date (YYYY-MM-DD)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.startDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(date1)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <!-- Event End Date -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="date2"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      v-model="editedItem.endDate"
                      :error-messages="errorField.endDate"
                      label="Event End Date (YYYY-MM-DD)"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.endDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(date2)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <!-- Event Language -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-select
                  dense
                  filled
                  :items="languages"
                  item-text="isoLanguageName"
                  item-value="isoLanguageCode"
                  label="Event Language"
                  v-model="editedItem.language"
                  :error-messages="errorField.language"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                ></v-select>
              </v-col>

              <!-- Event Category -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-select
                  dense
                  filled
                  :items="eventCategoriesData"
                  label="Event Category"
                  v-model="editedItem.eventCategory"
                  :error-messages="errorField.eventCategory"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                ></v-select>
              </v-col>

              <!-- Event status -->
              <v-col cols="12" sm="12" md="12" class="py-0">
                <v-select
                  dense
                  filled
                  :items="statusOptions"
                  label="Status"
                  v-model="editedItem.status"
                  :error-messages="errorField.status"
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="closeEditDialog()"
          >
            Cancel
          </v-btn>
          <v-btn color="green lighten-1" class="text-light" @click="saveEdit()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  emits: ["toggleEventPage"],
  data: () => ({
    apiGetEvents: new Api(),
    apiDelEvent: new Api(),
    apiSaveEdit: new Api(),
    apiGetEventCategories: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu1: false,
    menu2: false,
    loadingDialog: false,
    btnDisabled: false,
    headers: [
      {
        text: "No.",
        align: "start",
        sortable: false,
        value: "number",
      },
      { text: "Title", value: "title" },
      { text: "Event Start Date", value: "startDate" },
      { text: "Event End Date", value: "endDate" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedItem: {
      title: null,
      startDate: null,
      endDate: null,
      language: null,
      status: null,
      eventCategory: null,
    },
    errorField: {
      title: null,
      startDate: null,
      endDate: null,
      language: null,
      status: null,
      eventCategory: null,
    },
    eventData: [],
    dialogDelete: false,
    editDialog: false,
    deleteItemData: null,
    statusOptions: [
      {
        text: "Active",
        value: true,
      },
      {
        text: "Inactive",
        value: false,
      },
    ],
    eventCategoriesData: [],
  }),
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetEvents: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/get-events`,
      },
      apiDelEvent: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/del-event`,
        method: "post",
      },
      apiSaveEdit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/update-event`,
        method: "put",
      },
      apiGetEventCategories: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/get-event-categories`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetEventCategories.setQuery(`event_type=${this.hrdcType}`);
    this.apiGetEventCategories.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          for (const item of data) {
            this.eventCategoriesData.push({
              text: item.category,
              value: item.uuid,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    });

    this.apiGetEvents.setQuery(`event_type=${this.hrdcType}`);
    this.apiGetEvents.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (status) {
          this.eventData = data;
        }

        this.apiGetEventCategories.fetch();
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetEvents.fetch();

    this.rolesAssignation(this.auth.roles);
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    toggleEventPage() {
      this.$emit("toggleEventPage", "EventAdd");
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    },
    deleteItem(item) {
      this.deleteItemData = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.apiDelEvent.setParams({
        id: this.deleteItemData.id,
      });
      this.apiDelEvent.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;
          if (status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "success",
              timeout: 2000,
            });
            this.apiGetEvents.fetch();
            return true;
          }

          this.$store.dispatch("showMessage", {
            message: "Error, please contact admin to assist",
            messageType: "error",
            timeout: 2000,
          });
        } catch (err) {
          console.log(err);
        }
      });

      this.apiDelEvent.fetch();

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    getStatusIcon(status) {
      return status ? "mdi-check-circle" : "mdi-close-circle";
    },
    getStatusColor(status) {
      return status ? "green" : "red";
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    saveEdit() {
      this.showLoadingDialog();
      this.editedItem.startDate = new Date(this.editedItem.startDate);
      this.editedItem.endDate = new Date(this.editedItem.endDate);

      const params = {
        ...this.editedItem,
        userUuid: this.auth.uuid,
      };

      this.apiSaveEdit.setParams(params);
      this.apiSaveEdit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();

          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: "Please fill in all the required fields",
              messageType: "error",
              timeout: 2000,
            });

            return false;
          }

          if (response.status) {
            this.apiGetEvents.fetch();

            this.closeEditDialog();
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.hideLoadingDialog();
        }
      });
      this.apiSaveEdit.fetch();
    },
  },
};
</script>
